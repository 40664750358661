<template>
  <div>
    <GenericForm
      title="instrumento"
      previousRoute="controleEstoque"
      editRoute="instrumentoEditar"
      :model="model"
      route="item"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_item"
      :permissionsToWrite="['rw_material']"
      :permissionsToDelete="['rd_material']"
      :loadOnSave="true"
    >
      <template #header><div></div></template>
      <template v-slot="{ validateState, isBusy }">
        <div>
          <b-row align-v="center">
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group :key="refreshGenericSearch" label="Tipo de Instrumento*" label-for="tipoInstrumento">
                <GenericSelectSearch
                  route="tipoItem/listWithSearch"
                  name="tipoItem"
                  labelKey="descricao"
                  idKey="id_tipo_item"
                  :value="model.tipoItem"
                  @input="instrumentoSelect"
                  :minCharSearch="3"
                  v-validate="{ required: true }"
                  :state="validateState('tipoItem')"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Fabricante*" label-for="fabricante">
                <GenericSelect
                  name="fabricante"
                  labelKey="nome"
                  v-model="model.id_fabricante"
                  route="fabricante"
                  v-validate="{ required: true }"
                  :state="validateState('fabricante')"
                ></GenericSelect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Quantidade de peças*" label-for="quantidade_pecas">
                <b-form-input
                  id="quantidade_pecas"
                  v-model="model.quantidade_pecas"
                  autocomplete="off"
                  class="invision-input"
                  name="quantidade_pecas"
                  type="number"
                  min="1"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('quantidade_pecas')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Leitura do QR Code do Instrumento*" label-for="code">
                <b-form-input
                  id="code"
                  v-model="model.code"
                  autocomplete="off"
                  class="invision-input"
                  name="code"
                  :disabled="isBusy"
                  @input="handleChangeNewCode"
                  v-validate="{ required: true }"
                  :state="validateState('code')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div :class="editing ? 'button-foto-editing' : 'button-foto'">
            <Button
              v-if="hasFotos"
              @click="openModalFoto"
              variant="primary-light"
              :text="fotosButtonText"
            />
          </div>
        </div>
      </template>
    </GenericForm>
    <Modal
      id="modalPhoto"
      ref="modalPhoto"
      nome="modalPhoto"
      :title="editing ? 'Fotos do Material' : 'Editar fotos do material'"
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
      <PhotoForm
        :photos="modalPhotos"
        :buttonConfirmar="true"
        downloadRoute="item/downloadFotos"
        :onlyVisualize="model.onlyVisualizeFotos"
        @newPhoto="setImage"
        @confirmar="closeModalFoto"
        :photosName="photosName"
      />
    </Modal>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';
import GenericSelect from '@/components/Form/GenericSelect';
import GenericSelectSearch from '@/components/Form/GenericSelectSearch';
import Button from '@/components/Utils/Button';
import PhotoForm from '@/components/WebCam/PhotoForm';
import Modal from '@/components/Utils/Modal';
import genericRequest from '@/services/genericRequest';
import { debounce } from '@/helpers/common';

export default {
  components: {
    GenericForm,
    GenericSelect,
    GenericSelectSearch,
    Button,
    PhotoForm,
    Modal,
  },
  provide() {
    return { parentValidator: this.$parent.$validator };
  },
  props: {
    tipoMaterial: {
      type: String,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_item: this.$route.params.id || -1,
        id_tipo_item: null,
        id_fabricante: null,
        quantidade_pecas: null,
        code: '',
        onlyVisualizeFotos: true,
        fotos: [],
        descricao: '',
      },
      refreshGenericSearch: 0,
      modalPhotos: [],
    };
  },

  computed: {
    hasFotos() {
      return ((this.model.fotos && this.model.fotos.length));
    },
    photosName() {
      if (this.model.descricao || this.model.id_item !== -1) {
        return `${this.model.descricao}_ID-${this.model.id_item}`;
      }
      return 'item';
    },
    fotosButtonText() {
      let text = (this.editing || this.model.onlyVisualizeFotos) ? 'Visualizar fotografias' : 'Inserir fotografias';
      if (this.model.fotos.length) text = `${text} (${this.model.fotos.length})`;
      return text;
    },
  },

  methods: {
    instrumentoSelect(itemSelected) {
      this.model.id_tipo_item = itemSelected ? itemSelected.id_tipo_item : null;
      this.model.tipoItem = itemSelected;
      this.model.descricao = itemSelected ? itemSelected.descricao : '';
      this.model.fotos = itemSelected ? itemSelected.fotoTipoItem : [];
    },
    async updateModel(model) {
      this.model = model;
      if (this.editing) {
        this.refreshGenericSearch += 1;
      }
    },
    openModalFoto(urls) {
      this.modalPhotos = urls || this.model.fotos;
      this.$refs.modalPhoto.show();
    },
    closeModalFoto() {
      this.modalPhotos = [];
      this.$refs.modalPhoto.hide();
    },
    setImage(images) {
      this.model.fotos = images;
    },
    handleChangeNewCode: debounce(function debouncing(code) {
      this.handleDetectNewCode(code);
    }, 500),

    async handleDetectNewCode(code) {
      try {
        if (!code) return;
        const newCode = await this.getNewItem(code);
        if (newCode === 'OK') {
          return;
        }
        this.model.code = '';
      } catch (error) {
        console.log('error ao buscar item. error:', error);
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        } else {
          errorMessage = 'Ocorreu um problema ao recuperar o instrumento lido';
        }
        swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        this.model.code = '';
      }
    },
    getNewItem(code) {
      return genericRequest.get({}, `item/byCode/${code}/materialComposition`);
    },
  },
};
</script>

<style>
.box-change-material-type {
  font-size: 15px;
  margin-bottom: 10px;
  color: #21cca9;
}
.button-foto {
  position: absolute;
  background-color: transparent;
  bottom: 0px;
  right: 145px;
  z-index: 2;
}
.button-foto-editing {
  position: absolute;
  background-color: transparent;
  bottom: 1px;
  left: 250px;
  z-index: 2;
}
.num-photos {
  align-self: flex-start;
  padding-left: 10px;
}
.tooltip-icon {
  width: 16px;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
  color: #35384d;
}
.material-nav {
  list-style: none;
  padding-inline-start: 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #CFD1E6;
  margin-bottom: 36px;
}
.material-nav li{
  color: #7E829F;;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 24px 14px 24px;
}
.nav-item-active {
  border-bottom: 3px solid #209F85 !important;
  color: #209F85 !important;
  margin-bottom: -2.5px;
}
.info-yes-no {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #35384D;
}
</style>
